import React, { useState, useEffect } from 'react';
import SpotTheBallCoin from './contracts/SpotTheBallCoin.json';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import logo from './logo.png';
import mmLogo from './mm-logo.svg';
import './App.css';


function App() {

  

  const home_path = 'https://spottheballcoin.com/'
  
  const game_id = window.location.pathname.replace("/", "")


  useEffect(() => {
    
    const init = async () => {
      const provider = await detectEthereumProvider();

      const ethereumButton = document.querySelector('.enableEthereumButton');
      const redirectButton = document.querySelector('.redirectButton');
      const metataskStatus = document.querySelector('.metataskStatus');
      const balanceAmount = document.querySelector('.balanceAmount');

      if (provider) {
 
        metataskStatus.innerHTML = '';
        ethereumButton.classList.remove("d-none");
       
          
        ethereumButton.addEventListener('click', () => {

          Payment();

        });

        async function Payment() {

          const stbcAmount = window.globalConfig || { stbcAmount: process.env.REACT_APP_SITENAME}   ;
          const amount = stbcAmount.stbcAmount ;
          metataskStatus.innerText = '';
          ethereumButton.innerText = 'Waiting...';
          ethereumButton.disabled = true;

          const accounts = await provider.request({ method: 'eth_requestAccounts' });
          const account = accounts[0];
          

          
          const web3 = new Web3(window.web3.currentProvider);
          const contractInstance = new web3.eth.Contract(SpotTheBallCoin, '0x5fae000b3fbb6b6cf856a0161c3f1bf1421ca279');

          /*const result = await contractInstance.methods.balanceOf(account).call(); 
          
          const accountBalance = web3.utils.fromWei(result); 
          balanceAmount.innerText = 'Balance STBC : '+accountBalance;*/

          console.log(amount)
          const accountBalance = 166;
          if(accountBalance >= amount){

            const tx = {
              from: account,
              to: contractInstance._address,
              data: contractInstance.methods.transfer('0xE975BB6d808777dc9DB57F2372375Be0c6c382d9', web3.utils.toWei( amount.toString() ) ).encodeABI(),
            }

            web3.eth.sendTransaction(tx).then(res => {
                const form = new FormData()
                form.set('game_user_id', game_id)

                const resss = fetch(home_path+'game/update_game_payment', {
                  method: 'post',
                  mode:'no-cors',
                  body: form,
                 })
                 
                 .then((response) => {

                    const redirectUrl = home_path+'game/show_result/'+game_id;

                    ethereumButton.classList.add("d-none");
                    metataskStatus.innerHTML = 'Payment done Successfully';
                    metataskStatus.classList.remove("text-danger");
                    metataskStatus.classList.add("text-success");
                    redirectButton.innerHTML = 'Click <a href="'+redirectUrl+'" >Here</a> To redirect , if no auto-redirected.'

                    window.location.href = redirectUrl;
                 })
                 .catch((error) => {
                   console.error(error);
                 });

            }).catch(err => {
              
                ethereumButton.innerText = 'Connect MetaMask';
                ethereumButton.disabled = false;
                metataskStatus.innerText = 'Payment not Successfully';
                metataskStatus.classList.add("text-danger");
            });

          }else{
            metataskStatus.innerText = 'Not Enough STBC Token Balance.';
            metataskStatus.classList.add("text-danger");
            ethereumButton.classList.add("d-none");
            redirectButton.innerHTML = 'Check <a href="https://uniswap.org/">Uniswap</a> Or <a href="https://pancakeswap.finance/">Pancakeswap</a> , to Buy/Swap STBC Token.';

          }

        }

      } else {
        metataskStatus.innerHTML = 'Please install <a href="https://metamask.io">MetaMask</a>! ';
      }
    };
    
    init();
  }, []);
  


  return (
    <div className="App">
      <header className="App-header">

        <img src={logo} className="App-logo" alt="logo" />


        <p>Make your payment via your Metamask wallet to complete your entry</p>
        <span className="balanceAmount mb-5"></span>
        <img src={mmLogo}  className="mt-5 mb-2 mmLogo"/>
        <button className="btn btn-primary enableEthereumButton d-none mt-1">Connect MetaMask</button>

        <div className="redirectButton"></div>

        <span className="metataskStatus">Waiting...</span>
      </header>
    </div>
  );

}

export default App;
